import { MgpPayload, MgpStepNames } from "@earned/wizard";
import { StateCreator, create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import _ from "lodash";

interface MgpState {
  fields: MgpPayload;
  setField: <V>(field: { key: keyof MgpPayload; value: V }) => void;
  reset: () => void;
  navHistory: MgpStepNames[];
  setNavHistory: (history: MgpStepNames[]) => void;
}

type MgpPersist = (
  config: StateCreator<MgpState>,
  options: PersistOptions<MgpState>
) => StateCreator<MgpState>;

const isReactNative =
  typeof navigator !== "undefined" && navigator.product === "ReactNative";

let AsyncStorage: any;

if (isReactNative) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const asyncStorage = require("@react-native-async-storage/async-storage");
  AsyncStorage = asyncStorage.default;
}

const initialState = {
  fields: {} as MgpPayload,
  navHistory: [] as MgpStepNames[],
};
const useMgpStore = create<MgpState>(
  (persist as MgpPersist)(
    (set) => ({
      ...initialState,
      reset: () => set(initialState),
      setNavHistory: (history) => set({ navHistory: history }),
      setField: (field) =>
        set((state) => ({
          ...state,
          fields: _.set(
            { ...state.fields },
            field.key,
            field.value
          ) as MgpPayload,
        })),
    }),
    {
      name: "mgp-wizard-state",
      version: 0.1,
      getStorage: () => (isReactNative ? AsyncStorage : localStorage),
    }
  )
);

export { useMgpStore };
